import { Component, Input } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { HoldingDashboardDetails } from 'src/app/models/classes/holding-dashboard-details.class';
import { CurrencySymbolPipe } from 'src/app/pipes/currency-symbol.pipe';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { FinancialPerformanceWidgetComponent } from './financial-performance-widget/financial-performance-widget.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-case-study',
  standalone: true,
  imports: [
    MatIconModule, 
    CurrencySymbolPipe, 
    DateFormatPipe, 
    MatChipsModule, 
    FinancialPerformanceWidgetComponent,
    CommonModule
  ],
  templateUrl: './case-study.component.html'
})
export class CaseStudyComponent {
  investmentSummary: any;
  @Input() holdingSummary: HoldingDashboardDetails;
  @Input() caseStudyDetails: any;
  status: string;
  investmentHighlights: any;
  statusAndPerformance: any;
  performanceChartData: any;
  investmentSummaryItems: any;

  ngOnChanges(): void {
    if (this.caseStudyDetails) {
      this.investmentSummary = this.caseStudyDetails.summary;
      this.investmentHighlights = this.caseStudyDetails.highlights;
      this.statusAndPerformance = this.caseStudyDetails.performanceFindings;
      this.performanceChartData = this.caseStudyDetails.widgets;
      this.investmentSummaryItems = [
        { label: 'Investment Date', value: this.investmentSummary.investmentDate, isDate: true },
        { label: 'Total GCM Commitment', value: this.investmentSummary.totalGcmGrosvenorCommitment },
        { label: 'Sponsor', value: this.investmentSummary.investmentManagerName },
        { label: 'Deal Type', value: this.investmentSummary.strategy },
        { label: 'Industry', value: this.investmentSummary.industry },
        { label: 'Geography', value: this.investmentSummary.region },
        { label: 'Total Enterprise Value', value: this.investmentSummary.totalEnterpriseValue },
        { label: 'Entry Valuation', value: this.investmentSummary.entryValuation },
        { label: 'Entry Net Debt', value: this.investmentSummary.entryNetDebt },
        { label: 'Entry Net Leverage', value: this.investmentSummary.entryNetLeverage },
        { label: 'Entry EBITDA', value: this.investmentSummary.entryEbitda }
      ];
    }
  }
}
