import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../core/http/api.service';
import { InvestorMeta } from '../models/classes/investor-meta.class';

@Injectable({
    providedIn: 'root'
})

export class InvestorsStorageService {

    private investorsSubject$ = new BehaviorSubject<InvestorMeta[]>(null);
    public activeInvestorId: number;
    private activeInvestor$ = new BehaviorSubject<InvestorMeta>(null);

    constructor(private apiService: ApiService) {}

    fetchInvestorMetadata() {
        this.apiService.getInvestors().subscribe((investors: InvestorMeta[]) => {
            this.investorsSubject$.next(investors);
        })
    }

    setActiveInvestor(id: number): void {
        this.activeInvestorId = id;
        this.activeInvestor$.next(this.investorsSubject$.value.filter(investor => investor.id == id)[0]);
    }

    getInvestors(): Observable<InvestorMeta[]> {
        return this.investorsSubject$.asObservable();
    }

    getActiveInvestor(): Observable<InvestorMeta> {
        return this.activeInvestor$.asObservable();
    }
}
