import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { DataSeries } from 'src/app/models/classes/data-series.class';
import { KeyValuePair } from 'src/app/models/classes/key-value-pair.class';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { ActivityTrackingService } from 'src/app/services/activity-tracking.service';
import { HighchartsService } from 'src/app/services/highcharts-service.service';
import { UserService } from 'src/app/services/user.service';
import { BaseGeographyWidgetComponent } from 'src/app/shared/base-geography-widget/base-geography-widget.component';
import { BaseWidgetComponent } from 'src/app/shared/base-widget/base-widget.component';
import { GcmChartComponent } from 'src/app/shared/gcm-chart/gcm-chart.component';
import { InfoIconComponent } from 'src/app/shared/info-icon/info-icon.component';

@Component({
  selector: 'app-remaining-value-widget',
  standalone: true,
  imports: [
    MatTabsModule,
    BaseGeographyWidgetComponent,
    InfoIconComponent,
    BaseWidgetComponent,
    GcmChartComponent
  ],
  templateUrl: './remaining-value-widget.component.html',
  styleUrls: ['./remaining-value-widget.component.scss']
})
export class RemainingValueWidgetComponent implements OnInit {
  @Input() widgets: Record<string, KeyValuePair<string | number, number>[]> = { remainingValueBySector: [], remainingValueByMapRegion: [] };
  @Input() currency: string;
  remainingValueBySectors: DataSeries;
  remainingValueBySectorsChartOptions = null;
  remainingValueByAssetStatus: DataSeries;
  remainingValueByAssetStatusChartOptions = null;
  chartStyle: Record<string, string> = { width: '99%', display: 'flex' };
  tabs = ['Geography', 'Sectors', 'Private/Public']
  constructor(private highchartsService: HighchartsService, private currencyFormat: CurrencyFormatPipe, private userService: UserService, private activityTracking: ActivityTrackingService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgets && this.widgets) {
      this.calculateValuesForSectorsWidget();
      this.calculateValuesForAssetStatusWidget();
    }
  }

  calculateValuesForSectorsWidget() {
    this.remainingValueBySectors = this.highchartsService.buildDataSeries(this.widgets['remainingValueBySector']?.slice(0, 10), 'Remaining Market Value')
    if (this.widgets['remainingValueBySector'].length > 0) {
      let remainingValueForSectorSum = this.widgets['remainingValueBySector'].map(a => a.value).reduce((total, val) => total + val, 0);
      this.remainingValueBySectorsChartOptions = this.buildRemainingValueBySectorChartOptions(remainingValueForSectorSum);
    }
  }

  calculateValuesForAssetStatusWidget() {
    this.remainingValueByAssetStatus = this.highchartsService.buildDataSeries(this.widgets['remainingValueByAssetStatus'], 'Remaining Market Value')
    if (this.widgets['remainingValueByAssetStatus'].length > 0) {
      let remainingValueByAssetStatusSum = this.widgets['remainingValueByAssetStatus'].map(a => a.value).reduce((total, val) => total + val, 0);
      this.remainingValueByAssetStatusChartOptions = this.buildRemainingValueByAssetStatusChartOptions(remainingValueByAssetStatusSum);
    }
  }

  buildRemainingValueBySectorChartOptions(sum) {
    const self = this;

    if (sum <= 0) {
      this.remainingValueBySectors.error = this.highchartsService.noResultsText;
      return {};
    }

    return {
      chart: {
        height: 360
      },
      plotOptions: {
        series: {
          dataLabels: {
            formatter: function () {
              let valToShow = ((Math.abs(this.y) / Number(sum)) * 100).toFixed(1) + '%';
              return valToShow;
            }
          }
        }
      },
      tooltip: {
        formatter: function () {
          let valToShow = self.currencyFormat.transform(this.y, self.currency);
          const data = `${this.series.name} <br/>` + valToShow + ' <br/><hr class="mt-2 mb-2 mr-0 ml-0" style="background-color: #fff">'
          const footer = `<div>${this.key}</div>`
          return data + footer
        }
      },
      series: [{
        color: '#4a87af'
      }]
    }
  }

  buildRemainingValueByAssetStatusChartOptions(sum) {
    const self = this;

    if (sum <= 0) {
      this.remainingValueByAssetStatus.error = this.highchartsService.noResultsText;
      return {};
    }

    return {
      chart: {
        height: 320,
        type: 'pie',
        marginLeft: 50,
        marginRight: 50
      },
      plotOptions: {
        pie: {
          size: '90%',
          innerSize: '65%'
        },
        series: {
          dataLabels: {
            formatter: function () {
              let valToShow = `${this.key} <br/>` + ((Math.abs(this.y) / Number(sum)) * 100).toFixed(1) + '%';
              return valToShow;
            },
            padding: 0,
            distance: 10,
            alignTo: 'plotEdges'
          },
          states: {
            hover: {
              halo: null
            }
          }
        }
      },
      tooltip: {
        formatter: function () {
          let valToShow = self.currencyFormat.transform(this.y, self.currency);
          const data = `${this.series.name} <br/>` + valToShow + ' <br/><hr class="mt-2 mb-2 mr-0 ml-0" style="background-color: #fff">'
          const footer = `<div>${this.key}</div>`
          return data + footer
        }
      },
      series: [{
        allowPointSelect: false,
        colors: ['#4a87af', '#d1d3d5']
      }]
    }
  }
  
  handleChange(event: MatTabChangeEvent) {
    this.activityTracking.widgetTabChange('Remaining value widget', this.tabs[event.index])
    this.userService.raygunTrackMatTabChange(event);
  }
}
