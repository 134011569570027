<div class="grid grid-cols-4 gap-4 md:gap-y-0">
    <div class="row-span-2 col-span-4 md:col-span-1">
        <!-- investment summary -->
        <div class="widget-card card bg-white">
            <div class="border-b border-b-light-grey p-3 font-bold text-lg">
                <mat-icon class="text-primary align-middle material-icons-outlined mr-1.5">summarize</mat-icon>
                Investment Summary
            </div>
            <div class="p-3 text-md grid sm:grid-cols-2 md:grid-cols-1">
                <div class="py-2" *ngFor="let item of investmentSummaryItems">
                    <div class="font-semibold">
                        {{ item.label }}
                    </div>
                    <div>
                        <ng-container *ngIf="item.isDate; else normalValue">
                            {{ item.value | dateFormat:'MMM yyyy' }}
                        </ng-container>
                        <ng-template #normalValue>
                            {{ item.value }}
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grid grid-rows-2 gap-4 col-span-4 md:col-span-3">
        <div class="grid grid-cols-1">
            <!-- financial performance -->
            <app-financial-performance-widget [investmentSummary]="investmentSummary" [performanceChartData]="performanceChartData"></app-financial-performance-widget>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <!-- investment highlights -->
            <div class="widget-card card bg-white">
                <div class="border-b border-b-light-grey p-3 font-bold text-lg">
                    <mat-icon class="text-primary align-middle material-symbols-outlined mr-1.5" color="text-primary">lightbulb</mat-icon>
                    Investment Highlights
                </div>
                <div class="p-3  text-md">
                    <ul *ngFor="let highlight of investmentHighlights" class="list-disc ml-4">
                        <li class="mb-2"> {{highlight}}</li>
                    </ul>
                </div>
            </div>
            <!-- status and performance -->
            <div class="widget-card card bg-white">
                <div class="border-b border-b-light-grey p-3 flex justify-between">
                    <div class="flex items-center">
                        <mat-icon class="text-primary self-center material-symbols-outlined mr-1.5" color="text-primary">monitoring</mat-icon>
                        <div class="font-bold text-lg pl-1">Status and Performance</div>
                        <div class="font pl-2 text-grey text-sm">
                        as of {{investmentSummary?.financialsDate | dateFormat : 'MM/dd/yyyy'}}
                        </div>
                    </div>
                    <div>
                        <div class="status-chip" [ngClass]="status == 'On Plan' ? 'on-plan' : status == 'Below Plan' ? 'below-plan' : status == 'Above Plan' ? 'above-plan' : 'unknown'">
                            {{status}}
                        </div>
                    </div>
                </div>
                <div class="p-3 text-md">
                    <ul *ngFor="let bulletpoint of statusAndPerformance" class="list-disc ml-4">
                        <li class="mb-2"> {{bulletpoint}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>