import { Asset } from "./asset.class";
import { HoldingDashboardDetails } from "./holding-dashboard-details.class";
import { KeyValuePair } from "./key-value-pair.class";
import { Widget } from "./widget.class";
import { CoInvestmentDashboard } from "./co-investment-dashboard.class";

export class HoldingDashboard {
    summary: HoldingDashboardDetails;
    widgets: Record<Widget, KeyValuePair<string | number, number>[]>;
    assets: Asset[];
    coInvestmentCaseStudy: CoInvestmentDashboard;
}