<div class="h-full widget-card ml-0">
    <app-base-widget [error]="error" [card]="false">
        <div header class="border-b border-b-light-grey p-3 font-bold text-lg">
            <mat-icon class="text-primary align-middle material-icons-outlined mr-1.5" color="text-primary">paid</mat-icon>
            Financial Performance ({{investmentSummary?.currency | currencySymbol}} in millions, FYE {{investmentSummary?.fiscalYearEnd}})
        </div>
        <div class="p-3">
            <gcm-chart [chartStyle]="performanceChartStyle" chartType="column"
                [chartOptions]="performanceChartOptions" />
        </div>
    </app-base-widget>
</div>