import { Injectable } from '@angular/core';
import OktaSignIn from '@okta/okta-signin-widget';
import { OktaAuth } from '@okta/okta-auth-js';

import { EnvironmentVariablesService } from '../services/environment-variables.service';

@Injectable({
  providedIn: 'root'
})
export class Okta {
  static widget: OktaSignIn;
  static OktaAuth: OktaAuth;

  constructor(private evService: EnvironmentVariablesService) {

    let config = {
      issuer: evService.oktaConfigs['Issuer'],
      clientId: evService.oktaConfigs['ClientId'],
      redirectUri: evService.productionURL + '/investor',
      scopes: evService.oktaConfigs['Scope'],
      pkce: true,
      features: {
        idpDiscovery: evService.oktaConfigs['IdpEnable']
      },
      idpDiscovery: {
        requestContext: window.location.href
      }
    }
    Okta.OktaAuth = new OktaAuth(config);
    Okta.OktaAuth.start();

    Okta.widget = new OktaSignIn({
      baseUrl: this.evService.oktaConfigs['BaseUrl'],
      authClient: Okta.OktaAuth,
      clientId: this.evService.oktaConfigs['ClientId'],
      redirectUri: this.evService.productionURL + '/investor',
      scopes: this.evService.oktaConfigs['Scope'],
      features: {
        idpDiscovery: this.evService.oktaConfigs['IdpEnable']
      },
      idpDiscovery: {
        requestContext: window.location.href
      },
      i18n: {
        en: {
          // Labels
          'primaryauth.title': 'Log In',
          'primaryauth.username.placeholder': 'Email ID *',
          'primaryauth.password.placeholder': 'Password',
          'primaryauth.submit': 'Log In',
          // Errors
          'oform.errorbanner.title': 'We found some errors. Please review the form and make corrections.',
          'error.username.required': 'Please enter Email ID',
          'error.password.required': 'Please enter a password',
          'errors.E0000004': 'Incorrect username or password'
        }
      },
      useInteractionCodeFlow: false, // Disable Interaction Code flow
      useClassicEngine: true
    });
  }

  getWidget() {
    return Okta.widget;
  }

  getOktaAuth() {
    return Okta.OktaAuth;
  }
}
