import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { TermsComponent } from './terms/terms.component';
import { ContactComponent } from './contact/contact.component';
import { OktaAuthGuard } from '@okta/okta-angular';
import { InvestorDashboardComponent } from './investor-dashboard/investor-dashboard.component';
import { SeriesDashboardComponent } from './series-dashboard/series-dashboard.component';
import { HoldingsDashboardComponent } from './holdings-dashboard/holdings-dashboard.component';
import { AssetDashboardComponent } from './asset-dashboard/asset-dashboard.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { AuthErrorComponent } from './auth-error/auth-error.component';
import { DocumentReviewComponent } from './document-review/document-review.component';
import { AdminAuthGuard } from './guards/admin-auth.guard';
import { GcmNotfoundComponent } from '@gcm/gcm-ui-angular';
import { CustomGcmAppComponent } from './shared/custom-gcm-app/custom-gcm-app.component';

export const GCMRoutes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'investor' },
    // Show header and sidenav only when user is logged in with Okta.
    {
        path: '',
        component: CustomGcmAppComponent,
        canActivate: [OktaAuthGuard],
        children: [
            { path: 'investor', component: InvestorDashboardComponent },
            { path: 'investor/:investorId', component: InvestorDashboardComponent },
            { path: 'investor/:investorId/series/:seriesId', component: SeriesDashboardComponent },
            { path: 'investor/:investorId/series/:seriesId/holding/:holdingId', component: HoldingsDashboardComponent },
            { path: 'investor/:investorId/series/:seriesId/holding/:holdingId/asset/:assetId', component: AssetDashboardComponent },
            { path: 'contact', component: ContactComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'document-review', component: DocumentReviewComponent, canActivate: [AdminAuthGuard] },
        ]
    },

    { path: 'login', component: LoginComponent },
    { path: 'app/main', pathMatch: 'prefix', redirectTo: 'login' },
    { path: 'terms', component: TermsComponent, canActivate: [OktaAuthGuard] },
    { path: '404', component: GcmNotfoundComponent },
    { path: 'auth-error', component: AuthErrorComponent },
    { path: '**', redirectTo: '/404' },
];

@NgModule({
    imports: [RouterModule.forRoot(GCMRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }