import { CommonModule } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { GcmMatDialogService } from '@gcm/gcm-ui-angular';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { HoldingDashboardDetails } from 'src/app/models/classes/holding-dashboard-details.class';
import { KeyValuePair } from 'src/app/models/classes/key-value-pair.class';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { CurrencySymbolPipe } from 'src/app/pipes/currency-symbol.pipe';
import { IrrValueFormatPipe } from 'src/app/pipes/irr-value-format.pipe';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';
import { TvpiValueFormatPipe } from 'src/app/pipes/tvpi-value-format.pipe';
import { ActivityTrackingService } from 'src/app/services/activity-tracking.service';

@Component({
  selector: 'app-holding-dashboard-summary',
  standalone: true,
  imports: [
    CommonModule,
    CurrencyFormatPipe,
    TvpiValueFormatPipe,
    IrrValueFormatPipe,
    CurrencySymbolPipe,
    SafeHtmlPipe,
    TourMatMenuModule
  ],
  templateUrl: './holding-dashboard-summary.component.html',
  styleUrls: ['./holding-dashboard-summary.component.scss']
})
export class HoldingDashboardSummaryComponent implements AfterViewChecked, OnChanges {
  @Input() iconSet: KeyValuePair<string, string>;
  @Input() cfmanagerLogo: string = '';
  @Input() holdingTitle: string;
  @Input() holdingSummary: HoldingDashboardDetails;
  @Input() holdingSummaryText: string;
  showSummary: boolean = false;
  noResultsSeriesDetails: boolean = false;
  portfolioSeriesDetailsNoResults: boolean = false;
  showMoreBtn: boolean = false;
  @ViewChild('summaryElement') targetSummaryElement: ElementRef;
  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.setShowMoreVisibility();
  }

  constructor(
    private ref: ChangeDetectorRef,
    private activityTracking: ActivityTrackingService,
    private dialogService: GcmMatDialogService
  ) { }

  openModal() {
    this.activityTracking.viewMoreSummary(this.holdingSummary.masterId, this.holdingTitle);
    this.dialogService.showInformationDialog(this.holdingTitle, this.holdingSummaryText);
  }

  setShowMoreVisibility() {
    if (this.targetSummaryElement) {
      this.showMoreBtn = this.targetSummaryElement.nativeElement.offsetHeight < this.targetSummaryElement.nativeElement.scrollHeight ||
      this.targetSummaryElement.nativeElement.offsetWidth < this.targetSummaryElement.nativeElement.scrollWidth
      this.ref.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.holdingSummaryText) {
      this.showSummary = this.holdingSummaryText !== '' && this.holdingSummaryText !== null ? true : false;
    }
  }

  ngAfterViewChecked(): void {
    this.setShowMoreVisibility();
  }
}
