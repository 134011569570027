import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import Highcharts from 'highcharts';
import { CoInvestmentDetails } from 'src/app/models/classes/co-investment-details.class';
import { DeviceType } from 'src/app/models/enums/breakpoint.enum';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { CurrencySymbolPipe } from 'src/app/pipes/currency-symbol.pipe';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { BaseWidgetComponent } from 'src/app/shared/base-widget/base-widget.component';
import { GcmChartComponent } from 'src/app/shared/gcm-chart/gcm-chart.component';

@Component({
  selector: 'app-financial-performance-widget',
  standalone: true,
  imports: [BaseWidgetComponent, GcmChartComponent, MatIconModule, CurrencySymbolPipe],
  templateUrl: './financial-performance-widget.component.html'
})
export class FinancialPerformanceWidgetComponent {
  performanceChartStyle = { width: '100%', display: 'flex' };
  performanceChartOptions = {};
  linelegendsymbol: any = null;
  error: "";
  reflowEvent;
  @Input() investmentSummary: CoInvestmentDetails;
  @Input() performanceChartData: any;

  constructor(
    private breakpointService: BreakpointService,
    private currencyFormat: CurrencyFormatPipe,
  ) {}

  ngOnInit() {
    this.createPerformanceChart();
  }

  createPerformanceChart() {
    let self = this;
    //Creating Performance widget with Highcharts
    this.linelegendsymbol = Highcharts['seriesTypes'].line.prototype.drawLegendSymbol;
    Highcharts['seriesTypes'].line.prototype.drawLegendSymbol = Highcharts['seriesTypes'].area.prototype.drawLegendSymbol;
    this.breakpointService.getDeviceType().subscribe((deviceType: DeviceType) => {
      const isMobileDevice = deviceType === DeviceType.Handset;
      this.performanceChartOptions = {
        chart: {
          height: 400
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: this.performanceChartData.map(x => x.quarter),
          labels: {
            staggerLines: isMobileDevice ? 2 : 0,
            style: {
              fontSize: isMobileDevice ? '10px' : '12px'
            }
          }
        },
        yAxis: {
          title: {
            text: ''
          },
          labels: {
            enabled: true,
            reserveSpace: true,
            formatter: function (context) {
              //show in millions, if less than 1 million then show 1 decimal point
              let fractionDigits = Math.abs(context.value) >= 1000000 ? 0 : 1
              let valToShow = self.currencyFormat.transform(Number(context.value), 'USD', 'Millions', false, false, fractionDigits, true);
              return valToShow;
            },
            style: {
              fontSize: isMobileDevice ? '10px' : '12px'
            }
          },
          gridLineDashStyle: 'Dash',
          gridLineWidth: 1,
          gridLineColor: '#e6e6e6'
        },
        legend: {
          enabled: true,
          align: 'center',
          verticalAlign: 'top',
          itemStyle: {
            cursor: 'default',
            fontWeight: 'light',
            fontSize: isMobileDevice ? '11px' : '13px'
          },
          itemHoverStyle: {
            transition: 'none'
          },
          symbolHeight: 12,
          symbolWidth: 12,
          x: -18
        },
        tooltip: {
          backgroundColor: 'rgba(9, 49, 79, 0.90)',
          borderColor: '#fff',
          borderRadius: 8,
          style: {
            color: '#fff',
            fontSize: isMobileDevice ? '11px' : '14px'
          },
          useHTML: true,
          formatter: function () {
            let valToShow = this.series.name.includes('%') ?  `${Number(this.point.margin)}%` : self.currencyFormat.transform(Number(this.y), 'USD', 'Millions', false, false, 1, true);
            const data = `${this.series.name} <br/>` + valToShow + ' <br/><hr class="mt-2 mb-2 mr-0 ml-0" style="background-color: #fff">'
            const footer = `${this.x}`
            return data + footer
          },
          borderWidth: 0.5,
          shadow: true
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false
            }
          },
          column: {
            grouping: true,
            groupPadding: 0.2,
            pointPadding: 0.1,
            dataLabels: {
              enabled: false
            },
            states: {
              inactive: {
                opacity: 1
              }
            },
            events: {
              legendItemClick: function () {
                return false;
              }
            }
          }
        },
        series: [{
          name: 'Sales',
          data: this.performanceChartData.map(x => x.revenue),
          type: 'column',
          color: '#4887B2',
          states: {
            hover: {
              color: '#4887B2'
            }
          }
        }, {
          name: 'EBITDA',
          data: this.performanceChartData.map(x => x.adjEbitda),
          type: 'column',
          color: '#09314F',
          states: {
            hover: {
              color: '#09314F'
            }
          }
        }, {
          name: '% Margin',
          data: this.performanceChartData.map(x => ({y: x.revenue * 1.1, margin: x.margin})),
          type: 'line',
          color: '#48BEFF',
          borderColor: '#48BEFF',
          states: {
            hover: {
              lineWidth: 2,
            },
            inactive: {
              opacity: 1
            }
          },
          legend: {
            bubbleLegend: {
              borderColor: '#48BEFF',
              connectorColor: '#fff'
            }
          },
          events: {
            legendItemClick: function () {
              return false;
            }
          }
        }]
      }
    })
  }
}
