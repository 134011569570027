import { List } from "lodash";
import { KeyValuePair } from "./key-value-pair.class";
import { Widget } from "./widget.class";
import { CoInvestmentDetails } from "./co-investment-details.class";

export class CoInvestmentDashboard {
    summary: CoInvestmentDetails;
    highlights: List<string>;
    performanceFindings: List<string>;
    footerText: string;
    widgets: Record<Widget, KeyValuePair<string | number, number>[]>;
}