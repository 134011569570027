import { Component, Injectable, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ContentfulService } from './services/contentful.service';
import { EnvironmentVariablesService } from './services/environment-variables.service';
import { TimeoutService } from './services/timeout.service';
import { UserService } from './services/user.service';
import Highcharts from 'highcharts';
import * as HighchartsMap from 'highcharts/highmaps';
import { Okta } from './shared/okta.service';
import { TourService, IStepOption, TourStepTemplateComponent } from 'ngx-ui-tour-md-menu';
import { NgTourService } from './services/ng-tour-ui.service';
import { NgTourStepsService } from './services/ng-tour-steps.service';
import { RaygunV2 } from 'raygun4js';
import { CommonModule } from '@angular/common';
import { GcmLoadingComponent } from '@gcm/gcm-ui-angular';
import { SessionComponent } from './session/session.component';
import { InvestorsStorageService } from './services/investors-storage.service';
import { InvestorMeta } from './models/classes/investor-meta.class';
declare const rg4js: RaygunV2
declare const gtag: Function;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, TourStepTemplateComponent, GcmLoadingComponent, SessionComponent],
  templateUrl: './app.component.html'
})
@Injectable({
  providedIn: 'root', // Add this to ensure your SEO service will be app-wide available
})
export class AppComponent implements OnInit {
  containerId = 'clientScopeApp';
  title = 'client-experience';

  constructor(
    public router: Router,
    private evService: EnvironmentVariablesService,
    private titleService: Title,
    private metaService: Meta,
    private cfService: ContentfulService,
    private userService: UserService,
    public timeoutService: TimeoutService,
    private okta: Okta,
    private tourService: TourService,
    private ngTourUi: NgTourService,
    private ngTourSteps: NgTourStepsService,
    private investorStorage: InvestorsStorageService
  ) {
    this.addGAScript();
    this.setupRaygun();
    this.titleService.setTitle('ClientScope: A GCM Grosvenor Client Portal');
    this.metaService.updateTag({ name: 'description', content: 'ClientScope enables GCM Grosvenor clients to perform portfolio analysis and gain a detailed understanding of their long-term investments.' });
    
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url.includes('fromLogin')) {
        // Remove the "fromLogin" parameter.
        event.url = decodeURIComponent(event.url).replace('&fromLogin=true', '');
        // Navigate to the same route with the cleaned-up query parameters.
        this.router.navigateByUrl(event.url, { replaceUrl: true });
      }
      rg4js('trackEvent', {
        type: 'pageView',
        path: event.url
      });
      userService.setCurrentPageUrl(event.url);
      gtag('config', this.evService.googleAnalyticsID,
        {
          page_path: event.urlAfterRedirects
        }
      );
    });
    this.setHighchartOptions();
    this.timeoutService.setIdleConfigs();
    this.getContentfulData();
  }

  ngOnInit() {
    this.checkOktaAuthStatus();
    this.windowsEventListener();
    // Initialize UI Tour so that we can use it everywhere wanted.
    this.initializeUiTour();
  }

  setupRaygun(){
    rg4js('apiKey', this.evService.raygunApiKey);
    rg4js('options', { apiEndpoint: window.location.origin + '/logging' })
    rg4js('enablePulse', true);
    rg4js('enableCrashReporting', true);
  }

  setHighchartOptions() {
    Highcharts.setOptions({
      chart: {
        style: {
          fontFamily: 'Open Sans'
        }
      },
      credits: {
        enabled: false
      }
    });
    HighchartsMap.setOptions({
      chart: {
        style: {
          fontFamily: 'Open Sans'
        }
      },
      credits: {
        enabled: false
      }
    });
  }

  /** Add Google Analytics Script Dynamically */
  addGAScript() {
    const gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.evService.googleAnalyticsID;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    gtag('config', this.evService.googleAnalyticsID, { send_page_view: false });
  }
  
  checkOktaAuthStatus() {
    this.okta.getOktaAuth().authStateManager.updateAuthState().then((authState) => {
      if (authState.isAuthenticated) {
        this.cfService.initializeContentfulKey();
        this.userService.initUserInfo();
        // fetch investor metadata so we can use it everywhere and fetch only if the data is not available
        this.investorStorage.getInvestors().subscribe((investorsMeta: InvestorMeta[]) => {
          if (!investorsMeta || investorsMeta.length == 0) {
            this.investorStorage.fetchInvestorMetadata();
          }
        })
      }
    });
  }

  windowsEventListener() {
    window.addEventListener('storage', (event) => {
      if (event.key === 'session-continue') {
        this.timeoutService.resetSessionIdleData();
      }
    }, false);
  }

  getContentfulData() {
    // fetch iconSet only if the data is not available
    this.cfService.getIconSetValue().subscribe(iconSet => {
      if (!iconSet) {
        this.cfService.fetchCommonAssets('iconUrl', '').subscribe((data: any) => {
          this.cfService.setIconSetValue(data);
        });
      }
    })

    // fetch terms only if the data is not available
    this.cfService.getTermsValue().subscribe(tncDetails => {
      if (!tncDetails) {
        this.cfService.fetchCommonAssets('terms', '').subscribe((data: any) => {
          this.cfService.setTermsValue(data);
        });
      }
    })
  }

  initializeUiTour() {
    this.tourService.setDefaults(this.ngTourSteps.defaultOptions);
    this.ngTourUi.getTourSteps().subscribe((steps: IStepOption[]) => {
      this.tourService.initialize(steps);
    });
  }
}
