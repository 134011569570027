import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { DataSeries } from 'src/app/models/classes/data-series.class';
import { KeyValuePair } from 'src/app/models/classes/key-value-pair.class';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { HighchartsService } from 'src/app/services/highcharts-service.service';
import { BaseWidgetComponent } from 'src/app/shared/base-widget/base-widget.component';
import { GcmChartComponent } from 'src/app/shared/gcm-chart/gcm-chart.component';
import { InfoIconComponent } from 'src/app/shared/info-icon/info-icon.component';

@Component({
  selector: 'app-sectors-widget',
  standalone: true,
  imports: [BaseWidgetComponent, InfoIconComponent, GcmChartComponent],
  templateUrl: './sectors-widget.component.html'
})
export class SectorsWidgetComponent implements OnInit, OnChanges {
  @Input() widgets: Record<string, KeyValuePair<string | number, number>[]>;
  @Input() currency: string;
  chartStyle = { width: '99%', display: 'flex' };
  sectorByRemainingMarketValue: DataSeries;
  sectorByRemainingMarketValueChartOptions = {};

  constructor(private highchartsService: HighchartsService, private currencyFormat: CurrencyFormatPipe) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgets && this.widgets) {
      this.sectorByRemainingMarketValue = this.highchartsService.buildDataSeries(this.widgets['remainingValueBySector']?.slice(0, 10), 'Remaining Market Value')
      if (this.widgets['remainingValueBySector'] && this.widgets['remainingValueBySector'].length > 0) {
        let remainingValueForSectorSum = this.widgets['remainingValueBySector'].map(a => a.value).reduce((total, val) => total + val, 0);
        this.sectorByRemainingMarketValueChartOptions = this.buildSectorByRemainingMarketValueChartOptions(remainingValueForSectorSum);
      }
    }
  }

  buildSectorByRemainingMarketValueChartOptions(sum: number) {
    const self = this;
    if (sum <= 0) {
      this.sectorByRemainingMarketValue.error = this.highchartsService.noResultsText;
      return {};
    }

    return {
      chart: {
        height: 360
      },
      plotOptions: {
        bar: {
          pointWidth: 34
        },
        series: {
          dataLabels: {
            formatter: function () {
              let valToShow = ((Math.abs(this.y) / Number(sum)) * 100).toFixed(1) + '%';
              return valToShow;
            }
          }
        }
      },
      tooltip: {
        formatter: function () {
          let valToShow = self.currencyFormat.transform(this.y, self.currency);
          const data = `${this.series.name} <br/>` + valToShow + ' <br/><hr class="mt-2 mb-2 mr-0 ml-0" style="background-color: #fff">'
          const footer = `<div>${this.key}</div>`
          return data + footer
        }
      },
      series: [{
        color: '#4a87af'
      }]
    }
  }
}
