import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { ApiService } from '../core/http/api.service';
import { Document } from '../models/classes/document.class';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { PagedResponse } from '../models/classes/paged-response.class';
import { DocumentQuery } from '../models/classes/document-query.class';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { GcmDatePickerComponent, GcmLoadingService } from '@gcm/gcm-ui-angular';
import { MatOptionModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatInputModule } from '@angular/material/input';
import { ActivityTrackingService } from '../services/activity-tracking.service';

@Component({
  selector: 'app-document-review',
  standalone: true,
  imports: [
    MatPaginatorModule,
    MatTableModule,
    MatChipsModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatSortModule,
    MatIconModule,
    MatOptionModule,
    MatButtonModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    GcmDatePickerComponent
  ],
  templateUrl: './document-review.component.html',
  styleUrls: ['./document-review.component.scss']
})
export class DocumentReviewComponent implements OnInit, OnDestroy {
  documents = new PagedResponse();
  displayedColumns: string[] = ['reportingName', 'status', 'error', 'reportingPeriod', 'createdOn'];
  dataSource: Document[] = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  searchForm: FormGroup;
  dateRangeList: string[] = ["Last 7 days", "Last 30 days", "This Month", "This Quarter", "This Year"];
  @ViewChild('statusSelect') statusSelect: MatSelect;
  @ViewChild('documentDateSelect') documentDateSelect: MatSelect;
  @ViewChild('reportGenerationDateSelect') reportGenerationDateSelect: MatSelect;
  statusFilter: string[] = [];
  statusOptions: any[] = [
    {
      value: "Generated",
      display: "Generated"
    },
    {
      value: "GeneratedWithMissingData",
      display: "Generated With Missing Data"
    },
    {
      value: "GeneratedWithErrors",
      display: "Generated With Errors"
    },
    {
      value: "FailedToGenerate",
      display: "Failed To Generate"
    }
  ];
  documentDateFilters: string = '';
  reportGenerationDateFilters: string = '';
  documentStartDate: string;
  documentEndDate: string;
  reportGenerationStartDate: string;
  reportGenerationEndDate: string;
  selectedDocumentDate: string = '';
  selectedReportGenerationDate: string = '';
  @ViewChild('documentPicker') documentPicker: any;
  @ViewChild('reportGenerationPicker') reportGenerationPicker: any;
  allFilters: string[] = [];
  customDocumentDateRange: string = '';
  customReportGenerationDateRange: string = '';
  dateToday = new Date().toISOString();
  documentDateRange = new FormGroup({
    start: new FormControl(this.dateToday),
    end: new FormControl(this.dateToday)
  });
  reportGenerationDateRange = new FormGroup({
    start: new FormControl(this.dateToday),
    end: new FormControl(this.dateToday)
  });
  pageSizes: number[] = [10, 25, 50, 75, 100];
  page: number = 0;
  pageSize: number = 10;
  matSortEvent: Sort;
  @ViewChild('investorSelect') investorSelect: MatSelect;
  investorFilters: any[] = [];
  @ViewChild('portfolioSelect') portfolioSelect: MatSelect;
  portfolioFilters: any[] = [];
  fullPortfolioList: any[] = [];
  fullInvestorList: any[] = [];
  portfolioFiltersNames: string[] = [];
  investorFiltersNames: string[] = [];
  searchedInvestorList: any[] = [];
  searchedPortfolioList: any[] = [];
  investorsByPortfolios: any[] = [];
  portfoliosByInvestors: any[] = [];
  selectedInvestors: any[] = [];
  selectedPortfolios: any[] = [];
  investorSearch: FormControl<string> = new FormControl<string>('');
  portfolioSearch: FormControl<string> = new FormControl<string>('');
  _onDestroy = new Subject<void>();
  url = new URL(window.location.href);
  documentDateString = "Document Date: ";
  generationDateString = "Generation Date: ";
  gmtDocumentStartDate = new Date();
  gmtDocumentEndDate = new Date();
  gmtGenerationStartDate = new Date();
  gmtGenerationEndDate = new Date();

  constructor(
    private api: ApiService,
    private loading: GcmLoadingService,
    private formBuilder: FormBuilder,
    private dateFormat: DateFormatPipe,
    private activityTracking: ActivityTrackingService
  ) { }

  ngOnInit(): void {
    this.getPortfolioAndInvestorData();
    this.searchForm = this.formBuilder.group({
      statusFilter: [],
      documentDateFilter: [],
      reportGenerationDateFilter: [],
      investors: [],
      portfolios: []
    })
    this.applyExistingParams();
    this.listenToSearchChanges();
    this.activityTracking.landedOnPage('Document Review');
  }

  onSortChange(sort: Sort) {
    this.page = 0;
    this.matSortEvent = sort;
    this.loading.setLoading(true);
    this.getPagedResponse();
  }

  onPaginateChange(event: PageEvent) {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loading.setLoading(true);
    this.getPagedResponse();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  applyExistingParams() {
    this.statusFilter = this.url.searchParams.get('statusFilter')?.split(',') || [];
    this.form.statusFilter.setValue(this.statusFilter);

    this.documentDateFilters = this.url.searchParams.get('documentDateFilter') || null;
    this.form.documentDateFilter.setValue(this.documentDateFilters);
    this.documentStartDate = this.url.searchParams.get('documentStartDate');
    this.gmtDocumentStartDate = this.documentStartDate ? new Date(this.documentStartDate) : null;
    this.documentEndDate = this.url.searchParams.get('documentEndDate');
    this.gmtDocumentEndDate = this.documentEndDate ? new Date(this.documentEndDate) : null;

    this.reportGenerationDateFilters = this.url.searchParams.get('reportGenerationDateFilter') || null;
    this.form.reportGenerationDateFilter.setValue(this.reportGenerationDateFilters);
    this.reportGenerationStartDate = this.url.searchParams.get('reportGenerationStartDate');
    this.gmtGenerationStartDate = this.reportGenerationStartDate ? new Date(this.reportGenerationStartDate) : null;
    this.reportGenerationEndDate = this.url.searchParams.get('reportGenerationEndDate');
    this.gmtGenerationEndDate = new Date(this.reportGenerationEndDate);
    this.gmtGenerationEndDate = this.reportGenerationEndDate ? new Date(this.reportGenerationEndDate) : null;
    this.getAllFilters();
  }

  get form() {
    return this.searchForm.controls;
  }

  getPagedResponse() {
    const sort = this.matSortEvent?.active;
    const sortDirection = this.matSortEvent?.direction !== '' ? this.matSortEvent?.direction : null;
    const documentQuery = <DocumentQuery>{
      page: this.page,
      pageSize: this.pageSize,
      documentStartDate: this.documentStartDate?.slice(0, 10),
      documentEndDate: this.documentEndDate?.slice(0, 10),
      reportGenerationStartDate: this.reportGenerationStartDate?.slice(0, 10),
      reportGenerationEndDate: this.reportGenerationEndDate?.slice(0, 10),
      sort: sort,
      sortDesc: sortDirection && sortDirection === 'desc',
      portfolioIds: this.portfolioFilters,
      investorIds: this.investorFilters,
      status: this.statusFilter
    };

    this.api.getDocumentReports(documentQuery).subscribe({
      next: (res: PagedResponse) => {
        this.documents = res;
        this.documents.items.forEach(item => {
          item.displayStatus = this.getFullNameStatus(item.status)
        })
        this.assignToDataSource(this.documents);
      },
      error: (err) => {
        this.loading.setLoading(false);
      }
    })
  }

  assignToDataSource(documents: PagedResponse) {
    this.dataSource = documents.items;
    setTimeout(() => {
      this.paginator.pageIndex = this.page;
      this.paginator.length = documents.totalCount;
    });
    this.loading.setLoading(false);
  }

  getFullNameStatus(value) {
    let status = this.statusOptions.filter(status => status.value == value)[0];
    return status.display;
  }

  //filters methods:
  async applyFilters() {
    let statusFilterValue = this.form.statusFilter.value;
    let investorsFilterValue = this.selectedInvestors;
    let portfoliosFilterValue = this.selectedPortfolios;
    let selectedDocumentDatePeriod = this.form.documentDateFilter.value;
    let selectedReportGenerationDatePeriod = this.form.reportGenerationDateFilter.value;

    this.loading.setLoading(true);
    if (statusFilterValue?.length > 0) {
      this.statusFilter = statusFilterValue;
    }
    this.documentDateFilters = selectedDocumentDatePeriod;
    this.reportGenerationDateFilters = selectedReportGenerationDatePeriod;
    if (investorsFilterValue?.length > 0) {
      this.investorFilters = investorsFilterValue;
    }
    if (portfoliosFilterValue?.length > 0) {
      this.portfolioFilters = portfoliosFilterValue;
    }
    this.page = 0;
    this.setFilterParams()
    this.getAllFilters();
  }

  setFilterParams() {
    let filterParams = [];
    filterParams.push(["documentStartDate", this.documentStartDate ? [this.documentStartDate] : null])
    filterParams.push(["documentEndDate", this.documentEndDate ? [this.documentEndDate] : null])
    filterParams.push(["reportGenerationStartDate", this.reportGenerationStartDate ? [this.reportGenerationStartDate] : null])
    filterParams.push(["reportGenerationEndDate", this.reportGenerationEndDate ? [this.reportGenerationEndDate] : null])
    filterParams.push(["statusFilter", this.statusFilter])
    filterParams.push(["documentDateFilter", this.documentDateFilters && this.documentDateFilters != '' ? [this.documentDateFilters] : null])
    filterParams.push(["reportGenerationDateFilter", this.reportGenerationDateFilters && this.reportGenerationDateFilters != '' ? [this.reportGenerationDateFilters] : null])
    filterParams.push(["investors", this.investorFilters])
    filterParams.push(["portfolios", this.portfolioFilters])
    filterParams.forEach(filter => {
      let type = filter[0];
      this.url.searchParams.delete(type);
      let values = filter[1]
      if (values?.length > 0) {
        this.url.searchParams.append(type, values.join(','));
      }
    })
    history.replaceState({}, '', this.url.href)
  }

  async clearFilters() {
    if (this.allFilters.length > 0 || this.investorFiltersNames.length > 0 || this.portfolioFiltersNames.length > 0) {
      this.searchForm.reset();
      this.statusFilter = [];
      this.investorFilters = [];
      this.portfolioFilters = [];
      this.allFilters = [];
      this.portfolioFiltersNames = [];
      this.investorFiltersNames = [];
      this.selectedInvestors = [];
      this.selectedPortfolios = [];
      this.documentDateFilters = '';
      this.reportGenerationDateFilters = '';
      this.customDocumentDateRange = '';
      this.customReportGenerationDateRange = '';
      this.documentStartDate = null;
      this.documentEndDate = null;
      this.reportGenerationStartDate = null;
      this.reportGenerationEndDate = null;
      this.loading.setLoading(true);
      this.page = 0;
      this.searchedInvestorList = this.fullInvestorList;
      this.searchedPortfolioList = this.fullPortfolioList;
      this.setFilterParams();
      this.getPagedResponse();
    }
  }

  getAllFilters() {
    this.allFilters = []
    if (this.statusFilter.length > 0) {
      let statusFilterByDisplay = this.statusOptions.filter((status) => this.statusFilter.includes(status.value)).map((status) => status.display);
      this.allFilters.push(...statusFilterByDisplay)
    }
    if (this.documentDateFilters && this.documentDateFilters != '') {
      if (this.documentDateFilters === 'Custom') {
        const splitStartDate = this.documentStartDate.split('-')
        const splitEndDate = this.documentEndDate.split('-')
        const startYear = splitStartDate[0]
        const startMonth = splitStartDate[1]
        const startDay = splitStartDate[2].split(' ')[0]
        const endYear = splitEndDate[0]
        const endMonth = splitEndDate[1]
        const endDay = splitEndDate[2].split(' ')[0]
        this.customDocumentDateRange = this.documentDateString + startMonth + "/" + startDay + '/' + startYear + " - " + endMonth + "/" + endDay + '/' + endYear
        this.allFilters.push(this.customDocumentDateRange)
      } else {
        this.allFilters.push(this.documentDateString + this.documentDateFilters)
      }
    }
    if (this.reportGenerationDateFilters && this.reportGenerationDateFilters != '') {
      if (this.reportGenerationDateFilters === 'Custom') {
        const splitStartDate = this.reportGenerationStartDate.split('-')
        const splitEndDate = this.reportGenerationEndDate.split('-')
        const startYear = splitStartDate[0]
        const startMonth = splitStartDate[1]
        const startDay = splitStartDate[2].split(' ')[0]
        const endYear = splitEndDate[0]
        const endMonth = splitEndDate[1]
        const endDay = splitEndDate[2].split(' ')[0]
        this.customReportGenerationDateRange = this.generationDateString + startMonth + "/" + startDay + '/' + startYear + " - " + endMonth + "/" + endDay + '/' + endYear
        this.allFilters.push(this.customReportGenerationDateRange)
      } else {
        this.allFilters.push(this.generationDateString + this.reportGenerationDateFilters)
      }
    }
    this.getInvestorFilterNames();
    this.getPortfolioFilterNames();
    this.getPagedResponse();
  }

  removeFilter(removedFilter) {
    //remove error filter
    let removedStatusFilter = this.statusOptions.filter((status) => status.display === removedFilter)[0];
    this.statusFilter = this.statusFilter.filter((status) => status != removedStatusFilter.value);
    this.searchForm.patchValue({ statusFilter: this.statusFilter });

    //remove document date filter
    if (removedFilter === this.documentDateFilters || removedFilter === this.customDocumentDateRange || removedFilter.includes(this.documentDateString)) {
      this.documentDateFilters = ''
      this.customDocumentDateRange = ''
      this.documentStartDate = this.documentEndDate = null;
      this.searchForm.patchValue({ documentDateFilter: '' })
    }

    //remove report generation date filter
    if (removedFilter === this.reportGenerationDateFilters || removedFilter === this.customReportGenerationDateRange || removedFilter.includes(this.generationDateString)) {
      this.reportGenerationDateFilters = ''
      this.customReportGenerationDateRange = ''
      this.reportGenerationStartDate = this.reportGenerationEndDate = null;
      this.searchForm.patchValue({ reportGenerationDateFilter: '' })
    }

    this.applyFilters();
  }

  //portfolio and investor filters methods:
  getPortfolioAndInvestorData() {
    const getPortfolioData = this.api.getPortfoliosMetadata();
    const getInvestorData = this.api.getInvestors();

    forkJoin([getPortfolioData, getInvestorData]).subscribe({
      next: ([portfolioData, investorData]) => {
        this.fullPortfolioList = this.searchedPortfolioList = this.portfoliosByInvestors = portfolioData;
        this.fullInvestorList = this.searchedInvestorList = this.investorsByPortfolios = investorData;
        this.getCurrentPortfolioFilters();
        this.getCurrentInvestorFilters();
        //get document list
        this.getPagedResponse();
      },
      error: error => {
        console.log("Error:", error)
        //get document list anyways, without the portfolio and investors filters
        this.getPagedResponse();
      }
    });
  }

  getCurrentPortfolioFilters() {
    this.portfolioFilters = this.url.searchParams.get('portfolios')?.split(',').map(Number) || [];
    if (this.portfolioFilters) {
      this.portfolioFilters.forEach(id => {
        this.portfolioOptionClick(id)
      })
      this.form.portfolios.setValue(this.portfolioFilters);
      this.getPortfolioFilterNames();
    }
  }

  getCurrentInvestorFilters() {
    this.investorFilters = this.url.searchParams.get('investors')?.split(',').map(Number) || [];
    if (this.investorFilters) {
      this.investorFilters.forEach(id => {
        this.investorOptionClick(id)
      })
      this.form.investors.setValue(this.investorFilters);
      this.getInvestorFilterNames();
    }
  }

  getInvestorFilterNames() {
    if (this.investorFilters.length > 0) {
      this.investorFiltersNames = [];
      //get investor filter names to display as selected filters
      this.investorFiltersNames = this.fullInvestorList.filter((investor) => this.investorFilters.includes(investor.id)).map((investor) => investor.name);
    }
  }

  getPortfolioFilterNames() {
    if (this.portfolioFilters.length > 0) {
      this.portfolioFiltersNames = [];
      //get portfolio filter names to display as selected filters
      this.portfolioFiltersNames = this.fullPortfolioList.filter((portfolio) => this.portfolioFilters.includes(portfolio.id)).map((portfolio) => portfolio.name);
    }
  }

  removeOrAddIdToSelection(id: Number, arr: Array<Number>) {
    const index = arr.indexOf(id);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(id)
    }
    return arr;
  }

  investorOptionClick(id: Number) {
    this.selectedInvestors = this.removeOrAddIdToSelection(id, this.selectedInvestors);
    if (this.selectedInvestors && this.selectedInvestors.length > 0) {
      this.getPortfoliosByInvestors(this.selectedInvestors.toString())
    } else {
      this.searchedPortfolioList = this.portfoliosByInvestors = this.fullPortfolioList;
    }
  }

  getPortfoliosByInvestors(investorIds) {
    let investorIdsArray = investorIds.split(",").map(Number);
    let filteredList = [];
    investorIdsArray.forEach(investorId => {
      this.fullPortfolioList.forEach(portfolio => {
        if (portfolio.relatedInvestors.includes(investorId)) {
          if (!filteredList.includes(portfolio)) {
            filteredList.push(portfolio)
          }
        }
      })
    })
    this.searchedPortfolioList = this.portfoliosByInvestors = filteredList;
  }

  portfolioOptionClick(id: Number) {
    this.selectedPortfolios = this.removeOrAddIdToSelection(id, this.selectedPortfolios);
    if (this.selectedPortfolios && this.selectedPortfolios.length > 0) {
      this.getInvestorsByPortfolios(this.selectedPortfolios.toString());
    } else {
      this.searchedInvestorList = this.investorsByPortfolios = this.fullInvestorList;
    }
  }

  getInvestorsByPortfolios(portfolioIds) {
    let portfolioIdsArray = portfolioIds.split(",").map(Number);
    let filteredList = [];
    portfolioIdsArray.forEach(portfolioId => {
      this.fullInvestorList.forEach(investor => {
        if (investor.relatedPortfolios.includes(portfolioId)) {
          if (!filteredList.includes(investor)) {
            filteredList.push(investor)
          }
        }
      })
    })
    this.searchedInvestorList = this.investorsByPortfolios = filteredList;
  }

  removeInvestorFilter(removedFilter) {
    let removedInvestorFilterId = this.fullInvestorList.filter((investor) => investor.name === removedFilter)[0].id;
    this.investorFiltersNames = this.investorFiltersNames.filter(filter => filter != removedFilter);
    this.investorFilters = this.fullInvestorList.filter((investor) => this.investorFiltersNames.includes(investor.name)).map((investor) => investor.id);
    this.searchForm.patchValue({ investors: this.investorFilters });
    this.investorOptionClick(removedInvestorFilterId);
    this.applyFilters();
  }

  removePortfolioFilter(removedFilter) {
    let removedPortfolioFilterId = this.fullPortfolioList.filter((portfolio) => portfolio.name === removedFilter)[0].id;
    this.portfolioFiltersNames = this.portfolioFiltersNames.filter(filter => filter != removedFilter);
    this.portfolioFilters = this.fullPortfolioList.filter((portfolio) => this.portfolioFiltersNames.includes(portfolio.name)).map((portfolio) => portfolio.id);
    this.searchForm.patchValue({ portfolios: this.portfolioFilters });
    this.portfolioOptionClick(removedPortfolioFilterId);
    this.applyFilters();
  }

  listenToSearchChanges() {
    // listen for search field value changes
    this.investorSearch.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.dropDownSearch(this.investorSearch.value, "investor");
      });
    this.portfolioSearch.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.dropDownSearch(this.portfolioSearch.value, "portfolio");
      });
  }

  // Dropdown Search                                                                                                                              
  dropDownSearch(query: any, type: string) {
    let result = this.selectValue(query, type);
    type == 'investor' ? this.searchedInvestorList = result : '';
    type == 'portfolio' ? this.searchedPortfolioList = result : '';
  }

  // Dropdown Select value
  selectValue(query: any, type: string): any[] {
    let result: any[] = [];
    let searchOptions: any[] = [];
    type === 'investor' ? searchOptions = this.investorsByPortfolios : '';
    type === 'portfolio' ? searchOptions = this.portfoliosByInvestors : '';
    result = searchOptions.filter(element => element.name.toLowerCase().includes(query.toLowerCase()));
    return result;
  }

  dateRangePicker(type: string) {
    if (type === 'document') {
      this.documentPicker.open();
      this.documentDateSelect.open();
    }
    else {
      this.reportGenerationPicker.open();
      this.reportGenerationDateSelect.open();
    }
    this.setStartAndEndDate(type);
  }

  // Select Date Picker
  selectDatePicker(type: string) {
    this.setStartAndEndDate(type);
    type === 'document' ? this.documentDateSelect.close() : this.reportGenerationDateSelect.close();
  }

  setStartAndEndDate(type: string) {
    if (type === 'document') {
      this.documentStartDate = this.documentDateRange.value.start;
      this.documentEndDate = this.documentDateRange.value.end;
    }
    else {
      this.reportGenerationStartDate = this.reportGenerationDateRange.value.start;
      this.reportGenerationEndDate = this.reportGenerationDateRange.value.end;
    }
  }

  updateDateFilter(e, type: string) {
    if (type === 'document') {
      this.form.documentDateFilter.setValue(e.type);
      this.documentStartDate = this.dateFormat.transform(e.start, 'yyyy-MM-dd HH:mm:ss');
      this.documentEndDate = this.dateFormat.transform(e.end, 'yyyy-MM-dd HH:mm:ss');
    } else {
      this.form.reportGenerationDateFilter.setValue(e.type);
      this.reportGenerationStartDate = this.dateFormat.transform(e.start, 'yyyy-MM-dd HH:mm:ss');
      this.reportGenerationEndDate = this.dateFormat.transform(e.end, 'yyyy-MM-dd HH:mm:ss');
    }
  }
}
